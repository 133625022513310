
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Broadcaster } from "../../../../common/providers/broadcaster.service";
import { environment } from "../../../../../environments/environment";
import { CommonUtil } from "../../../../common/utils/common.utils";
import { takeWhile } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-task-aboutus-dialog",
  templateUrl: "./task-aboutus-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskAboutusDialogComponent implements OnDestroy {
  isAlive = true;
  app_version: string = "1.6.9.360"; // default version, for web; replace to 1.6.9.360 when deploy via Jenkins
  constructor(
    private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<TaskAboutusDialogComponent>,
    private changerDetectorRef: ChangeDetectorRef, ) {

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    this.broadcaster.on<any>("hideTaskAboutusDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hide();
      });

    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    }
  }

  deviceReady() {
  cordova.getAppVersion.getVersionNumber().then(versionNumber => {
    console.log("AboutDialogComponent versionNumber", versionNumber);

    if (CommonUtil.isOnAndroid()) {
      // for Android we use ${VERSION}.${env.BUILD_NUMBER} as a version
      // so do not need to pass a build number since version contains both.
      this.app_version = versionNumber;
      console.log("TaskAboutusDialogComponent Android this.app_version", this.app_version);

      this.changerDetectorRef.markForCheck();
    } else {
      cordova.getAppVersion.getVersionCode().then(buildNumber => {
        console.log("TaskAboutusDialogComponent buildNumber", buildNumber);

        this.app_version = versionNumber + "." + buildNumber;

        console.log("TaskAboutusDialogComponent iOS this.app_version", this.app_version);

        this.changerDetectorRef.markForCheck();
      });
    }
  });
}

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
