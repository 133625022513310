
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  HostListener,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { Priority } from "../../models/priority";
import { TranslateService } from "@ngx-translate/core";
import { User, Task, BulkUpdateArgs, ICompact, List, Location } from "../../models";
import { DatePipe } from "@angular/common";
import { BulkUpdateIssueType, DateFilterType, SuccessType } from "../../shared/task-enum";
import { TaskRepository } from "../../repository/task.repository";
import { TaskUtils } from "../../shared/task-utils";
import { TasksConstants } from "../../shared/task-constacts";
import { Store } from "@ngrx/store";
import { TasksRootState, getMemberList, getProjectsList, getPriorityList, getFolderList, getAuthUser, getTasksIsLoading, getLocationList, getTagList } from "../../store/index";
import { Project } from "../../models/project";
import { SuccessService } from "../../../common/providers/success-service";
import { ConfigService } from "../../../common/providers/config.service";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { TaskDatePickerComponent } from "../task-date-picker/task-date-picker.component";
import { getOnlineStatus } from "../../../reducers";
import { UntypedFormControl } from "@angular/forms";
import { CommonUtil } from "../../../common/utils/common.utils";
import { takeWhile, take } from "rxjs/operators";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-task-bulk-edit-dialog",
  template: `
      <div id="bulk-edit-priority-dialog" class="vnctask-bulk-edit-dialog task__dialog open">
      <div class="task__dialog-header">
        <div class="mobile-back-button">
          <button mat-button (click)="hideDialog()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
        </div>
        <div class="header_lbl disable-select">
          {{ header }}
        </div>
        <div class="desktop-close-button">
          <button mat-button (click)="hideDialog()">
            <mat-icon class="disable-select">close</mat-icon>
          </button>
        </div>
      </div>
        <div class="task__dialog-body" [class.save-mode]="((authUser ? (authUser.team_user === 'false'): true ) && email && email.value !== '') && dialogType == bulkUpdateIssueType.AssignedTo">

        <div class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Priority">
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let item of priorityList; let i = index;"
            [class.selected]="i == selectedIndex"
            (click)="onPriorityClick(item)">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/assignment-late</title>
                <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(3.000000, 1.000000)" [style.fill]="getPriorityColor(item)">
                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                    </g>
                </g>
              </svg>
              <span>{{item.name | uppercase | translate}}</span>
          </mat-list-item>
        </mat-list>
       </div>

      <div id="bulk-edit-assignedto-dialog" class="option-list assign-option-list" *ngIf="dialogType == bulkUpdateIssueType.AssignedTo">
        <mdl-textfield *ngIf="authUser ? (authUser.team_user === 'true'): false" class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_USER' | translate"  floating-label></mdl-textfield>
        <mat-form-field style="height: 85px;" class="" *ngIf="authUser ? (authUser.team_user === 'false'): true">
          <span class="invite-external-label">{{ 'ASSIGN_TO' | translate }}:</span>
          <input (input)="searchTextChange()" type="text" [(ngModel)]="searchText" matInput>
        </mat-form-field>

        <mat-list role="list" (scroll)="onScroll($event)">
          <mat-list-item role="listitem" *ngFor="let assignUser of searchAssignUserList; let i = index;"
           [class.selected]="i == selectedIndex"
           (click)="onAssignUsersClick(assignUser)">
           <vp-avatar [jid]="assignUser?.mail" [avatarId]="assignUser.id" [user]="{ name: assignUser && assignUser.name ? assignUser.name : '', avatarURL: assignUser.avatar }"> </vp-avatar>
           <span class="username-place">{{ (assignUser) ? assignUser.name : ('NO_NAME' | translate) }}</span>
          </mat-list-item>
        </mat-list>
      </div>
      <div id="bulk-edit-project-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Project">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_PROJECT' | translate"  floating-label></mdl-textfield>

      <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let project of projectList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onProjectClick(project)">
          <mat-icon>work</mat-icon>
          <span>{{project.name}}</span>
          </mat-list-item>
      </mat-list>
     </div>

     <div id="bulk-edit-list-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.List">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_LIST' | translate"  floating-label></mdl-textfield>
      <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let list of folderList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onListClick(list)">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Icon/product/assignment-24px</title>
                  <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                          <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                      </g>
                  </g>
            </svg>
            <span class="list-span">{{list.name}}</span>
          </mat-list-item>
      </mat-list>
     </div>

     <div id="bulk-edit-tag-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Tags">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'FIND_THE_TAG' | translate"  floating-label></mdl-textfield>

      <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let tag of tagList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onTagClick(tag)">
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/tag-new</title>
              <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
              </g>
          </svg>
          <span>{{tag.name}}</span>
          </mat-list-item>
      </mat-list>
     </div>

     <div id="bulk-edit-location-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Location">
      <mdl-textfield class="filter-search-textfield" [(ngModel)]="searchText"  [label]="'SEARCH_LOCATION' | translate"  floating-label></mdl-textfield>

      <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let location of locationList  | vpTaskProjectSearch : searchText; let i = index;"
          [class.selected]="i == selectedIndex"
          (click)="onLocationClick(location)">
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/product/location-new</title>
            <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                    <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
                </g>
            </g>
          </svg>
          <span class="list-span">{{location.name}}</span>
          </mat-list-item>
      </mat-list>
     </div>

     <div id="bulk-edit-recurring-dialog" class="option-list" *ngIf="dialogType == bulkUpdateIssueType.Recurring">


     <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let repeat of repeatItems; let i = index;"
         [class.selected]="i == selectedIndex"
         (click)="onRepeatClick(repeat.value)">
         <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/product/autorenew</title>
            <g id="Icon/product/autorenew" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 1.480291)" fill="#8B96A0">
                    <path d="M8,6.52075285 C8,7.07246132 8.31387329,7.20583612 8.70591205,6.81379735 L12,3.51970941 L8.70591205,0.22562146 C8.31604759,-0.164243002 8,-0.0376302616 8,0.518665968 L8,2.51970941 C3.58,2.51970941 0,6.09970941 0,10.5197094 C0,12.0897094 0.46,13.5497094 1.24,14.7797094 L2.7,13.3197094 C2.25,12.4897094 2,11.5297094 2,10.5197094 C2,7.20970941 4.69,4.51970941 8,4.51970941 L8,6.52075285 Z M13.3,7.71970941 C13.74,8.55970941 14,9.50970941 14,10.5197094 C14,13.8297094 11.31,16.5197094 8,16.5197094 L8,14.518666 C8,13.9669575 7.68612671,13.8335827 7.29408795,14.2256215 L4,17.5197094 L7.29408795,20.8137974 C7.68395241,21.2036618 8,21.0770491 8,20.5207528 L8,18.5197094 C12.42,18.5197094 16,14.9397094 16,10.5197094 C16,8.94970941 15.54,7.48970941 14.76,6.25970941 L13.3,7.71970941 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
         <span>{{repeat.name}}</span>
          </mat-list-item>
      </mat-list>
      </div>
        <div id="bulk-edit-date-dialog" class="date-option-list" *ngIf="dialogType === bulkUpdateIssueType.StartDate || dialogType === bulkUpdateIssueType.DueDate">
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let item of filteredItems; let i = index;" [class.selected]="i == selectedIndex" (click)="onDateSelect(item, dialogType)">
            <mat-icon *ngIf = "dialogType===bulkUpdateIssueType.StartDate && item.date">today</mat-icon>
            <mat-icon *ngIf = "dialogType===bulkUpdateIssueType.DueDate && item.date">event</mat-icon>
            <mat-icon *ngIf = "!item.date">event_busy</mat-icon>
            <div style="display: flex;width:100%;justify-content:space-between;">
              <div class="dateDesc">{{ item.name }}</div>
              <div class="align-right" *ngIf="item.date">{{item.date | vpLocaleDate: 'MMM d'}}</div>
              <div class="align-right" *ngIf="!item.date">None</div>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="footer-div">
          <button *ngIf = "dialogType===bulkUpdateIssueType.StartDate" class="date-picker-button mat-button" (click)='openDatePicker()'>
            <mat-icon style="vertical-align: middle;">today</mat-icon>
            <span>{{ 'PICK_START_DATE' | translate }}</span>
          </button>
          <button *ngIf = "dialogType===bulkUpdateIssueType.DueDate" class="date-picker-button mat-button" (click)='openDatePicker()'>
            <mat-icon style="vertical-align: middle;">event</mat-icon>
            <span>{{ 'PICK_DUE_DATE' | translate }}</span>
          </button>
        </div>
      </div>
        </div>
        <div *ngIf="((authUser ? (authUser.team_user === 'false'): true ) && email.value !== '') && dialogType == bulkUpdateIssueType.AssignedTo" class="task__dialog-footer">
        <div class="desktop-footer">
            <button mat-button (click)="hideDialog()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button (click)="saveInviteUserClick(searchText)">
                {{ 'UPDATE' | translate }}
            </button>
        </div>
        <div class="mobile-footer">
            <div class="title">
                {{ 'SAVE_ASSIGNED_USERS' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveInviteUserClick(searchText)">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
      </div>
      </div>
      <div class="vnctask-loading" *ngIf="isOnIOS && isLoading">
        <div class="indicator">
        <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
        </div>
      </div>
      <div class="vnctask-loading" *ngIf="isUpdateInProcess">
        <div class="indicator">
        <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
        </div>
      </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBulkEditDialogComponent implements OnInit, OnDestroy {
  filteredItems: any[] = [];
  selectedTasks: Task[] = [];
  item: string[] = [];
  dialogType: any;
  assignUserList: User[] = [];
  searchAssignUserList: User[] = [];
  priorityList: Priority[] = [];
  projectList: Project[] = [];
  tagList: ICompact[] = [];
  folderList: List[] = [];
  locationList: Location[] = [];
  repeatItems: any[] = [];
  @Input() header: string;
  datePipe: DatePipe = new DatePipe("en");
  bulkUpdateIssueType = BulkUpdateIssueType;
  isAlive = true;
  rangeStart = new Date();
  searchText: string = "";
  isOnline: boolean = false;
  authUser: any = {};
  email = new UntypedFormControl("");
  isLoading: boolean = false;
  isOnIOS: boolean = CommonUtil.isOnIOS();
  mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  projectId: number;
  isUpdateInProcess: boolean = false;
  msgs:any;
  pageSize = 200;
  currentIndex = 0;
  constructor(
    private translate: TranslateService,
    private vncLibaryService: VncLibraryService,
    private tasksRepo: TaskRepository,
    private store: Store<TasksRootState>,
    private successService: SuccessService,
    private configService: ConfigService,
    private broadcaster: Broadcaster,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<TaskBulkEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.messageTranslatorService.translatedMessagesd$.subscribe(res => {
      this.msgs = res;
    });
    this.dialogType = this.data.dialogType;
    this.selectedTasks = this.data.selectedTasks;
    if (this.data && this.data.projectId) {
      this.projectId = this.data.projectId;
    }
    this.setupStore();
    this.handleBulkUpdateSuccessMessages();
    this.rangeStart.setDate(this.rangeStart.getDate() - 1);

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.hideDialog();
      });

    this.broadcaster.on<any>("hideBulkEditDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.hideDialog();
      });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.authUser = user;
        this.changerDetectorRef.markForCheck();
      }
    });

    if (this.dialogType === BulkUpdateIssueType.AssignedTo) {
      if (this.authUser && this.authUser.team_user === "false") {
        this.tasksRepo.syncAuthUser();
      }
      this.openDialogForAssignUser(this.dialogType, this.selectedTasks, this.projectId);
    } else {
      this.show(this.dialogType, this.selectedTasks);
    }
  }

  ngOnInit() { }

  show(dialogType: any, selectedTasks: Task[]): void {

    if (dialogType === BulkUpdateIssueType.Priority) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.PRIORITY);
    } else if (dialogType === BulkUpdateIssueType.Project) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_PROJECT);
    } else if (dialogType === BulkUpdateIssueType.AssignedTo) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.ASSIGN_USER);
    } else if (dialogType === BulkUpdateIssueType.StartDate) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_START_DATE);
      this.setFilterDateOption(DateFilterType.StartDate);
    } else if (dialogType === BulkUpdateIssueType.DueDate) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TASK_DUE_DATE);
      this.setFilterDateOption(DateFilterType.DueDate);
    } else if (dialogType === BulkUpdateIssueType.Recurring) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.CHANGE_REPEAT_PATTERN);
      this.setRepeatItems();
    } else if (dialogType === BulkUpdateIssueType.Tags) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.TAGS);
    } else if (dialogType === BulkUpdateIssueType.List) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.LISTS);
    } else if (dialogType === BulkUpdateIssueType.Location) {
      this.header = this.messageTranslatorService.getMessage(TasksConstants.LOCATIONS);
    }
    this.changerDetectorRef.markForCheck();
  }

  setupStore() {
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe(projects => {
      if (projects) {
        this.projectList = projects;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getPriorityList).pipe(takeWhile(() => this.isAlive)).subscribe(priorities => {
      if (priorities) {
        this.priorityList = priorities;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getMemberList).pipe(takeWhile(() => this.isAlive)).subscribe(members => {
      if (members) {
        this.assignUserList = members;
        if (members?.length < this.pageSize) {
          this.pageSize = members.length;
        }
        this.searchAssignUserList = this.getItems(0, this.pageSize);
        this.currentIndex = this.pageSize;

        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getTagList).pipe(takeWhile(() => this.isAlive)).subscribe(tags => {
      if (tags) {
        this.tagList = tags;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getFolderList).pipe(takeWhile(() => this.isAlive)).subscribe(lists => {
      if (lists) {
        this.folderList = lists;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getLocationList).pipe(takeWhile(() => this.isAlive)).subscribe(locations => {
      if (locations) {
        this.locationList = locations;
        this.changerDetectorRef.markForCheck();
      }
    });
    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });
    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task-bulk-edit-dialog.component] isLoading: " + value);
      this.isLoading = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  searchTextChange() {
    if (this.searchText.trim() === "") {
      if (this.assignUserList?.length < this.pageSize) {
        this.pageSize = this.assignUserList.length;
      }
      this.searchAssignUserList = this.getItems(0, this.pageSize);
      this.currentIndex = this.pageSize;
      return;
    }
    if (this.searchText) {
      let filteredList = this.assignUserList.filter(mem => {
        return (mem.name.toLowerCase().includes(this.searchText.toLowerCase()) || mem.username.toLowerCase().includes(this.searchText.toLowerCase()));
      });
      this.searchAssignUserList = filteredList;
    }
  }

  handleBulkUpdateSuccessMessages() {
    // console.log("[" + this.constructor.name + "][handleBulkUpdateSuccessMessages]", this.dialogType );

    this.successService.only(SuccessType.TaskBulkUpdated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      if (success.taskId) {
        const id = success.taskId;
        const text = this.msgs.TASK_NEW_UPDATED.replace("{{ UpdatedTaskId }}", `#${id.toString()}`);
        this.vncLibaryService.openSnackBar(text, "task-update","", "", 3000, "bottom", "left");
      }
      else {
        this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
      }
      setTimeout(() => {
        this.isUpdateInProcess = false;
        this.changerDetectorRef.markForCheck();
      }, 100);
      if (this.dialogType !== BulkUpdateIssueType.Status) {
        this.hideDialog();
      }
      if (this.dialogType === BulkUpdateIssueType.Tags) {
        this.tasksRepo.getTagsListWithCounters();
      }
      if (this.dialogType === BulkUpdateIssueType.List) {
        this.tasksRepo.getFolderListWithCounters();
      }
      if (this.dialogType === BulkUpdateIssueType.Location) {
        this.tasksRepo.getLocationsWithCounters();
      }
      this.broadcaster.broadcast("setUnselectAllCheckbox");

      if ((this.dialogType !== BulkUpdateIssueType.AssignedTo) || (this.dialogType !== BulkUpdateIssueType.Priority) || (this.dialogType !== BulkUpdateIssueType.Project) || (this.dialogType !== BulkUpdateIssueType.Recurring) || (this.dialogType !== BulkUpdateIssueType.Tags) ) {
        this.tasksRepo.getTasksStats();
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  public hideDialog(): void {
    this.searchText = "";
    this.changerDetectorRef.markForCheck();
    this.dialogRef.close();
  }

  openDialogForAssignUser(
    dialogType: string,
    selectedTasks: Task[],
    projectId: number
  ) {
    this.tasksRepo.getMemberList(projectId);
    this.show(dialogType, selectedTasks);
  }

  onPriorityClick(priority: Priority) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: priority.id, name: priority.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Priority, item);
  }

  onProjectClick(project: Project) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: project.id, name: project.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Project, item);
  }

  onTagClick(tag: ICompact) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: tag.id, name: tag.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Tags, item);
  }

  onListClick(list: List) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: list.id, name: list.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.List, item);
  }

  onLocationClick(location: Location) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: location.id, name: location.name };
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.Location, item);
  }

  onDateSelect(event, type) {
    this.tasksRepo.FormattedDateForDB = event?.date;
    this.isUpdateInProcess = true;
    console.log("[TaskBulkEditDialog][onDateSelect]", event, type);

    let setDate = null;
    if (event !== null) {
      if (event.date || event.date === null) {
        setDate = this.dateSelect(event.date);
      } else {
        setDate = this.dateSelect(event);
      }
    }
    console.log("[TaskBulkEditDialog][onDateSelect] setDate", setDate);

    // validate due_date > start_date
    let defaultDate = TaskUtils.dueDateOrStartDateNull();
    if (setDate) {
      let invalidTasksCounter = 0;
      if (type === BulkUpdateIssueType.StartDate) {
        const sd = new Date(setDate);
        this.selectedTasks.forEach(t => {
          const dd = t.due_date;
          if (dd && dd.getTime() !== defaultDate.getTime() && dd < sd) {
            ++invalidTasksCounter;
          }
        });
      } else if (type === BulkUpdateIssueType.DueDate) {
        const dd = new Date(setDate);
        this.selectedTasks.forEach(t => {
          const sd = t.start_date;
          if (sd && sd.getTime() !== defaultDate.getTime() && dd < sd) {
            ++invalidTasksCounter;
          }
        });
      }

      console.log("[TaskBulkEditDialog][onDateSelect] invalidTasksCounter: ", invalidTasksCounter);

      if (invalidTasksCounter > 0) {
        this.tasksRepo.errorDueDateInvalid();
        this.isUpdateInProcess = false;
        return;
      }
    }

    const item: BulkUpdateArgs = { value: setDate };
    this.updateTask(this.selectedTasks, type, item);
  }

  updateTask(selectedTasks: Task[], type: BulkUpdateIssueType, item: BulkUpdateArgs) {
    if (type !== BulkUpdateIssueType.Recurring) {
      this.tasksRepo.updateBulkTasks(this.selectedTasks, type, item);
    } else {
      this.tasksRepo.updateBulkTasksRecurring(this.selectedTasks, type, item);
    }
  }

  dateSelect(event) {
    return this.datePipe.transform(event, "yyyy-MM-dd");
  }

  onAssignUsersClick(user) {
    this.isUpdateInProcess = true;
    let item: BulkUpdateArgs = { id: user.id, name: user.name };
    item["login"] = user?.name;
    if (user?.avatar) {
      item["avatar_url"] = user.avatar;
    }
    if (user?.username) {
      item["username"] = user.username;
    }
    if (user?.mail) {
      item["jid"] = user.mail;
    }
    this.updateTask(this.selectedTasks, BulkUpdateIssueType.AssignedTo, item);
  }

  saveInviteUserClick(email) {
    if (email.match(this.mailFormat)) {
      let canInvite = true;
      let isPermission = true;
      this.selectedTasks.forEach(task => {
        if (task.author.id !== this.authUser.id) {
          canInvite = false;
        }
        if (task.can_invite_users === "false") {
          isPermission = false;
        }
      });
      if (!isPermission) {
        const message = this.messageTranslatorService.getMessage(TasksConstants.PERMISSION_DECLINED);``
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        return false;
      }
      if (canInvite) {
        if (this.authUser.can_invite_users === "true") {
          let item: BulkUpdateArgs = { value: email };
          this.updateTask(this.selectedTasks, BulkUpdateIssueType.InviteTo, item);
        } else {
          const message = this.messageTranslatorService.getMessage(TasksConstants.EXCEED_LIMIT_FOR_INVITE);``
          this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
          return false;
        }
      } else {
        const message = this.messageTranslatorService.getMessage(TasksConstants.ONLY_AUTHOR_CAN_INVITE);``
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        return false;
      }
    } else {
      const message = this.messageTranslatorService.getMessage(TasksConstants.ENTER_VALID_EMAIL_ADDRESS);``
      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      return false;
    }
  }

  onRepeatClick(repeatUnit) {
    let item: BulkUpdateArgs = { value: repeatUnit };
    if (!this.isUpdateInProcess) {
      this.isUpdateInProcess = true;
      this.updateTask(this.selectedTasks, BulkUpdateIssueType.Recurring, item);
    }
  }

  setFilterDateOption(msg_no_date) {
    this.filteredItems = this.tasksRepo.getDateFilterItems(msg_no_date);
    this.changerDetectorRef.markForCheck();
  }

  setRepeatItems() {
    this.repeatItems = this.tasksRepo.getRepeatItems();
    this.changerDetectorRef.markForCheck();
  }

  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }

  openDatePicker() {
    const dlg = this.matDialog.open(TaskDatePickerComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-datepicker-dialog",
      data: { header: (this.dialogType === BulkUpdateIssueType.StartDate) ? this.messageTranslatorService.getMessage(TasksConstants.PICK_START_DATE) : this.messageTranslatorService.getMessage(TasksConstants.PICK_DUE_DATE), rangeStart: this.rangeStart}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      if (res) {
        this.onDateSelect(res, this.dialogType);
      }
    });
  }

  private onPushBugHack() {
    setTimeout(() => {
      this.changerDetectorRef.markForCheck();
    }, 100);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  onScroll(event) {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 10) {
      if (this.searchAssignUserList.length < this.assignUserList.length) {
        this.loadMoreItems();
      }
    }
  }

  loadMoreItems() {
    if (this.searchAssignUserList.length + 200 >= this.assignUserList.length) {
      const newItems = this.getItems(this.currentIndex, this.assignUserList.length - this.currentIndex);
      this.searchAssignUserList = [...this.searchAssignUserList, ...newItems];
      this.currentIndex = this.assignUserList.length;
    }
    else {
      if (this.assignUserList?.length < this.pageSize) {
        this.pageSize = this.assignUserList.length;
      }
      const newItems = this.getItems(this.currentIndex, this.pageSize);
      this.searchAssignUserList = [...this.searchAssignUserList, ...newItems];
      this.currentIndex += this.pageSize;
    }
  }

  getItems(startIndex: number, count: number) {
    const items = [];
    for (let i = startIndex; i < startIndex + count; i++) {
      if (i < this.assignUserList?.length) {
        items.push(this.assignUserList[i]);
      }
    }
    return items;
  }
}
