
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="task-item-div" class="task-item disable-select" (click)="onTaskClicked()" vpLongPress [timeout]="700" (onLongPress)="$event.stopPropagation();openContextMenu()" [ngClass]="{'selected-hightlight': task?.isSelected, 'collected-task': task?.isChecked }"
  *ngIf="task">
  <div class="task-item__check">
    <div class="prorityMark" [style.background]="getPriorityColor(task.priority)"></div>
    <vnc-checkbox style="margin-left: 6px;" class="no-rounded" (click)="$event.stopPropagation();" (change)="onSelectTask(task.isChecked);" [checked]="task.isChecked"></vnc-checkbox>
    <vp-avatar [avatarForLoggedInUser]="currentUserEmail === task?.assigned_to?.jid" [class.task-comment-avtar-device]="task?.isChecked" [stopPropagation]="true" [avatarId]="task?.assigned_to?.id" [jid]="task?.assigned_to?.jid" [menuDisabled]="getMenuDisabled(task?.assigned_to?.jid)" vncActionWheelMenu [type]="'detail-mobile-avatar'" [user]="{ name: task?.assigned_to && task?.assigned_to?.name ? task?.assigned_to?.name : '', avatarURL: task?.userAvatar }"></vp-avatar>

    <div class="task-item__avtar" *ngIf="task?.isChecked">
      <div class="task-icon-avtar task-done-avtar disable-select">
        <mdl-icon>done</mdl-icon>
      </div>
   </div>
    <div class="summary-pane-detail">
      <div>
        <span class="header-text disable-select">{{task.subject}}</span>
        <span *ngIf="task?.repeat && task?.repeat !== 'n'" class="repeat-icon">
          <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Icon/product/autorenew</title>
            <g id="Icon/product/autorenew" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 1.480291)" fill="#8B96A0">
                    <path d="M8,6.52075285 C8,7.07246132 8.31387329,7.20583612 8.70591205,6.81379735 L12,3.51970941 L8.70591205,0.22562146 C8.31604759,-0.164243002 8,-0.0376302616 8,0.518665968 L8,2.51970941 C3.58,2.51970941 0,6.09970941 0,10.5197094 C0,12.0897094 0.46,13.5497094 1.24,14.7797094 L2.7,13.3197094 C2.25,12.4897094 2,11.5297094 2,10.5197094 C2,7.20970941 4.69,4.51970941 8,4.51970941 L8,6.52075285 Z M13.3,7.71970941 C13.74,8.55970941 14,9.50970941 14,10.5197094 C14,13.8297094 11.31,16.5197094 8,16.5197094 L8,14.518666 C8,13.9669575 7.68612671,13.8335827 7.29408795,14.2256215 L4,17.5197094 L7.29408795,20.8137974 C7.68395241,21.2036618 8,21.0770491 8,20.5207528 L8,18.5197094 C12.42,18.5197094 16,14.9397094 16,10.5197094 C16,8.94970941 15.54,7.48970941 14.76,6.25970941 L13.3,7.71970941 Z" id="Shape"></path>
                </g>
            </g>
        </svg>
        </span>
      </div>
      <span class="tag-list-item" *ngIf="task?.tags && task?.tags?.length > 0">
          <span *ngFor="let tag of task?.tags; let i = index">
            <ng-container *ngIf="i <= 4">
              <span class="tag-text">{{ tag?.name }}</span>
            </ng-container>
            <ng-container *ngIf="i == 5 && !task?.isMoreTags">
                <span class="view-all-text" (click)="$event.stopPropagation();task.isMoreTags = true;">{{ 'VIEW_ALL' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="i > 4 && task?.isMoreTags">
                <span class="tag-text">{{ tag?.name }}</span>
            </ng-container>
          </span>
      </span>
    </div>
  </div>
  <div class="task-item__more">
    <div class="task-item__more__due disable-select" [ngClass]="(task?.status?.name !== 'Closed' && task?.status?.name !== 'Completed' && task?.due_date && task?.due_date < currentDate) ? 'task-item__more__due_date' : ''">
      <span>{{ dueDateDisplayFormat() }}</span>
    </div>
    <div class="task-item__more__due disable-select" [ngClass]="(task?.status?.name !== 'Closed' && task?.status?.name !== 'Completed' && task?.due_date && task?.due_date < currentDate) ? 'task-item__more__due_date' : ''">
      <span>{{ dueDateDisplayYear() }}</span>
    </div>
    <!-- <div class="task-item__more__status disable-select">
      <mdl-icon *ngIf="task.status.name === 'New'">panorama_fish_eye</mdl-icon>
      <mdl-icon *ngIf="task.status.name === 'In Progress'">timelapse</mdl-icon>
      <mdl-icon *ngIf="task.status.name === 'Closed' || task.status.name === 'Completed'">check_circle</mdl-icon>
    </div> -->
    <div class="task-item__more__progress disable-select">
      <div [ngStyle]="{'width': task.done_ratio + '%' }"></div>
    </div>
  </div>
</div>
