
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from "@angular/core";
import { Task } from "../../models";
import { DatePipe } from "@angular/common";
import { TaskUtils } from "../../shared/task-utils";
import { CommonUtil } from "../../../common/utils/common.utils";
import { LocaleDatePipe } from "../pipes/locale-date.pipe";
import { LocaleService } from "../../../common/providers/locale.service";
import { getAuthUser, TasksRootState } from "../../store";
import { Store } from "@ngrx/store";
import { take, takeWhile } from "rxjs/operators";
import { VNCActionWheelMenuService } from "vnc-library";
import { getUserProfile } from "src/app/reducers";

@Component({
  selector: "vp-vnctask-list-item",
  templateUrl: "task-list-item.html"
})
export class TaskListItemComponent implements OnDestroy {

  @Input() task: Task;
  @Output() onTaskClick = new EventEmitter<Task>();
  @Output() onTaskSelect = new EventEmitter<any>();
  currentDate: Date = new Date();
  private datePipe: LocaleDatePipe = new LocaleDatePipe(this.localeService);
  isLongPressed: boolean =  false;
  isAlive: boolean = true;
  user: any = {};
  currentUserEmail: string = "";
  constructor(private changerDetectorRef: ChangeDetectorRef,
    private localeService: LocaleService,
    private store: Store<TasksRootState>,
    private actionWheelMenuService: VNCActionWheelMenuService ) {
    this.currentDate.setHours(0, 0, 0, 0);

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.user = user;
        setTimeout( () => { this.changerDetectorRef.markForCheck(); }, 100);
      }
    });
    this.store.select(getUserProfile).pipe(take(1)).subscribe(user => {
      if (user) {
        this.currentUserEmail = user?.email;
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  onSelectTask(state: boolean): void {
    this.task.isChecked = !this.task.isChecked
    this.onTaskSelect.emit({
      state: this.task.isChecked,
      task: this.task
    });
  }

  dueDateDisplayFormat(): string {
    if (!this.task.due_date || ( (this.task.due_date) && (this.task.due_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime()))) {
      return "";
    }
    return this.datePipe.transform(this.task.due_date, "MMM dd");
  }

  dueDateDisplayYear(): string {
    if (!this.task.due_date || ( (this.task.due_date) && (this.task.due_date.getTime() === TaskUtils.dueDateOrStartDateNull().getTime()))) {
      return "";
    }
    return this.datePipe.transform(this.task.due_date, "yyyy");
  }

  private openContextMenu(): void {
    if (TaskUtils.isDeviceWidthOptimal()) {
      this.task.isChecked = !this.task.isChecked;
      this.isLongPressed = true;
      this.onSelectTask(this.task.isChecked);
    }
  }

  onTaskClicked() {
    if (CommonUtil.isOnIOS() && this.isLongPressed) {
      this.isLongPressed = false;
      return;
    }
    this.onTaskClick.emit(this.task);
  }
  ngOnDestroy(): void {
    this.actionWheelMenuService.hideCircularMenu();
    this.onTaskClick.unsubscribe();
    this.onTaskSelect.unsubscribe();
  }
  getPriorityColor(item) {
    return TaskUtils.getPriorityColor(item);
  }

  getMenuDisabled(email) {
    if (email === this.user.email) {
      return {
        one: false,
        two: false,
        three: false,
        four: false
      };
    }
    return {};
  }
}
